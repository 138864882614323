<!-- 文件描述: 任务中心-历史采集任务 -->
<!-- 创建时间: 2022/09/14；创建人: 阿苏 -->
<!-- 最后修改时间: 2022/09/14；最后修改人: 阿苏-->
<template>
    <div class="history-acquisition-page">
        <div class="active">
            <div class="active-info">
                <div class="item-info">
                    <p class="lable">任务模版：</p>
                    <p class="template-name">京东-评论模板</p>
                </div>
                <div class="item-info">
                    <p class="lable">任务名称：</p>
                    <p class="value">京东url下架商品</p>
                </div>
            </div>
            <div>
                <el-button type="primary" class="add-taskp-btn">清除历史</el-button>
                <el-button type="primary" class="add-taskp-btn">编辑任务</el-button>
            </div>
        </div>
        <div class="content">
            <TableModule :tableData="tableData" :page="true">
                <el-table-column prop="date" label="模版名称" width="180">
                </el-table-column>
                <el-table-column prop="name" label="状态" width="180">
                </el-table-column>
                <el-table-column prop="address" label="创建时间">
                </el-table-column>
                <el-table-column prop="address" label="操作人">
                </el-table-column>
                <el-table-column prop="address" label="任务描述">
                </el-table-column>
                <el-table-column prop="address" label="操作">
                </el-table-column>
            </TableModule>
        </div>
    </div>

</template>

<script>
import TableModule from "../../components/Table/TableModule.vue";

export default {
    name: 'AcquisitionsystemTaskResult',
    components: {
        TableModule
    },
    data() {
        return {
            activeName: "first",
            tableData: [{
                date: '2016-05-02',
                name: '王小虎',
                address: '上海市普陀区金沙江路 1518 弄'
            }, {
                date: '2016-05-04',
                name: '王小虎',
                address: '上海市普陀区金沙江路 1517 弄'
            }, {
                date: '2016-05-01',
                name: '王小虎',
                address: '上海市普陀区金沙江路 1519 弄'
            }, {
                date: '2016-05-03',
                name: '王小虎',
                address: '上海市普陀区金沙江路 1516 弄'
            }],
        };
    },

    mounted() {

    },

    methods: {

    },
};
</script>

<style lang="scss" scoped>
.history-acquisition-page {
    padding: 30px 24px;
}
.content{
    margin-top: 20px;
}
.active {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    .add-taskp-btn {
        width: 100px;
        height: 36px;
        background: #507CE5;
        border-radius: 4px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 36px;
        padding: 0;
    }
}

.active-info {
    display: flex;
    align-items: center;

    .item-info {
        display: flex;
        align-items: center;
        margin-right: 20px;

        .lable {
            font-size: 16px;
            font-family: SourceHanSansCN-Medium, SourceHanSansCN;
            font-weight: 500;
            color: #333333;
        }

        .value {
            font-size: 16px;
            font-family: SourceHanSansCN-Regular, SourceHanSansCN;
            font-weight: 400;
            color: #333333;
        }

        .template-name {
            width: 200px;
            height: 36px;
            line-height: 36px;
            background: #EBF0FF;
            border-radius: 4px;
            font-size: 16px;
            font-family: SourceHanSansCN-Regular, SourceHanSansCN;
            font-weight: 400;
            color: #507CE5;
        }
    }
}
</style> 